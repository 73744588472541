<template>
  <div class="leader-board" :class="props.size">
    <div class="table">
      <div class="table-head">
        <template v-if="!isCalculated">
          <div class="place"><span>#</span></div>
          <div>
            <span>{{ t('tournament.player') }}</span>
          </div>
          <div class="right">
            <span>{{ t('tournament.betsAmount') }}</span>
          </div>
          <div class="right prize">
            <span>{{ t('tournament.prize') }}</span>
          </div>
        </template>
        <template v-if="isCalculated">
          <div class="processed-head">
            <StIcon name="hourglass" :size="16" />
            {{ t('tournament.calculating') }}
          </div>
        </template>
      </div>
      <div class="table-body">
        <LeaderBoardRow
          v-for="item in prizesTopList"
          :key="item.prizePlace"
          :item="item"
          :currency-icon="currencyIcon"
          :is-current-user-place="
            item.prizePlace === currentUserPlace?.prizePlace
          "
          :size="props.size"
        />
        <div class="separator-row">
          <img v-if="props.size === 'm'" src="./dots.svg" width="40" alt="" />
          <img v-else src="./dots-line.svg" alt="" />
        </div>
        <LeaderBoardRow
          v-if="finalTopListRow"
          class="final-row"
          :item="finalTopListRow"
          :currency-icon="currencyIcon"
          :is-current-user-place="
            finalTopListRow.prizePlace === currentUserPlace?.prizePlace
          "
          :size="props.size"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Tournament } from '../../../types'
import { useTournament } from '../../../composables/useTournament'
import LeaderBoardRow from './LeaderBoardRow.vue'

const props = withDefaults(
  defineProps<{
    card: Tournament
    size?: 's' | 'm' | 'l'
  }>(),
  {
    size: 'm',
  },
)

const { t } = useI18n()

const { card } = toRefs(props)
const {
  prizesTopList,
  finalTopListRow,
  currentUserPlace,
  currencyIcon,
  isCalculated,
} = useTournament(card)
</script>

<style scoped>
.table-head {
  display: flex;
  gap: var(--spacing-100);

  margin-bottom: 1px; /* stylelint-disable-line */
  padding: var(--spacing-050) var(--spacing-150);

  font: var(--desktop-text-xs-medium);
  color: var(--text-quaternary, rgb(255 255 255 / 32%));

  background: var(--background-primary);
  border-radius: var(--border-radius-100) var(--border-radius-100) 0 0;

  > div {
    flex: 1 1 0;
    min-width: 0;
    text-align: left;

    &.place {
      flex-grow: 0;
      min-width: 36px;
    }

    &.right {
      text-align: right;
    }
  }
}

.processed-head {
  display: flex;
  gap: var(--spacing-100);
  align-items: center;
  justify-content: center;

  color: var(--text-secondary);
}

.table-body {
  display: flex;
  flex-direction: column;
  gap: 1px; /* stylelint-disable-line */
}

.separator-row {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 16px;

  background: rgb(31 31 41 / 60%);
}

.final-row {
  border-radius: 0 0 var(--border-radius-100) var(--border-radius-100);
}

.leader-board {
  &.m {
    .table-head {
      > div {
        &.prize {
          min-width: 140px;
        }
      }
    }
  }

  &.l {
    .table-head {
      margin: 0;
      padding: 0;
      padding-bottom: var(--spacing-050);

      > div {
        &.prize {
          min-width: 140px;
        }
      }
    }

    .table-body {
      gap: 0;
      padding-top: var(--spacing-125);
    }

    .separator-row {
      height: 26px;
    }
  }

  &.s {
    .table-head {
      margin: 0;
      padding: 0;
      padding-bottom: var(--spacing-050);

      > div {
        &.place {
          min-width: 28px;
        }
      }
    }

    .table-body {
      gap: 0;
      padding-top: 0;
    }

    .separator-row {
      overflow: hidden;
      align-items: flex-end;
      height: 6px;
    }
  }
}
</style>
