<template>
  <div class="table-row" :class="classes">
    <div class="place">
      <StIcon v-if="item.prizePlace <= 3" :name="prizeIconName" :size="20" />
      <template v-else>{{ item.prizePlace }}</template>
    </div>
    <div class="name">
      <div v-if="isCurrentUserPlace" class="current-user-pointer">
        {{ t('tournament.i') }}
        <img class="pointer" src="./pointer.svg" alt="" />
      </div>
      <span v-if="item.userNickname" :title="item.userNickname">
        {{ item.userNickname }}
      </span>
      <span v-if="!item.userNickname && item.userDropAmount" class="anonymous">
        <StIcon name="anonymous" size="16" />
        {{ t('tournament.anonymous') }}
      </span>
      <span v-if="!item.userDropAmount">-</span>
    </div>
    <div class="amount">
      <template v-if="!item.userDropAmount">-</template>
      <template v-else>
        <span>{{ userDropAmount }}</span>
        <StIcon
          v-if="currencyIcon"
          class="currency-icon"
          :name="currencyIcon"
          :size="16"
        />
      </template>
    </div>
    <div class="prize" :title="prizeAmount">
      <span>{{ prizeAmount }}</span>
      <StIcon
        v-if="currencyIcon"
        class="currency-icon"
        :name="currencyIcon"
        :size="16"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IconName } from '@st/ui/components/StIcon/types'
import type { PrizeTopList } from '../../../types'

const props = withDefaults(
  defineProps<{
    item: PrizeTopList
    isCurrentUserPlace: boolean
    currencyIcon: IconName | null
    size?: 's' | 'm' | 'l'
  }>(),
  {
    size: 'm',
  },
)

const { format } = useCryptoFormatter()
const { t } = useI18n()
const placesIconMap = {
  1: 'c-place1' as const,
  2: 'c-place2' as const,
  3: 'c-place3' as const,
}
const prizeIconName = computed(
  () => placesIconMap[props.item.prizePlace as keyof typeof placesIconMap],
)

const classes = computed(() => [
  { active: props.isCurrentUserPlace },
  props.size,
])
const { format: compactFormat } = useCryptoFormatter({
  notation: 'compact',
  maximumFractionDigits: 1,
})

const userDropAmount = computed(() =>
  ['s', 'm'].includes(props.size)
    ? compactFormat(props.item.userDropAmount ?? 0)
    : format(props.item.userDropAmount ?? 0),
)

const prizeAmount = computed(() =>
  props.size === 's'
    ? compactFormat(props.item.prizeAmount ?? 0)
    : format(props.item.prizeAmount ?? 0),
)
</script>

<style scoped>
.table-row {
  display: flex;
  gap: var(--spacing-100);
  align-items: center;

  padding: var(--spacing-100) var(--spacing-150);

  background: var(--background-primary);

  > div {
    display: flex;
    flex: 1 1 0;
    gap: var(--spacing-050);
    align-items: center;
    justify-content: flex-start;

    min-width: 0;

    font: var(--desktop-text-sm-medium);
    color: var(--text-secondary);

    &:first-child {
      flex-grow: 0;
      min-width: 36px;
    }

    &.amount {
      justify-content: flex-end;
    }

    &.prize {
      justify-content: flex-end;

      /* stylelint-disable */
      font-size: 16px;
      font-weight: 800;
      font-style: italic;
      line-height: 18px;
      color: var(--text-primary);
      /* stylelint-enable */

      span {
        overflow: hidden;
        display: inline;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &.name {
      span {
        overflow: hidden;
        display: inline;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .anonymous {
        display: flex;
        gap: var(--spacing-075);
        align-items: center;
      }
    }

    &.place {
      font: var(--desktop-text-sm-medium);
      color: var(--text-primary);
    }
  }

  &.active {
    background: linear-gradient(
        0deg,
        rgb(254 94 1 / 24%) 0%,
        rgb(254 94 1 / 24%) 100%
      ),
      var(--background-primary);
  }

  &.m {
    > div {
      &.prize {
        min-width: 140px;
      }
    }
  }

  &.l {
    padding: 0;

    & + .table-row {
      padding-top: var(--spacing-200);
    }

    > div {
      &.prize {
        min-width: 140px;
      }
    }
  }

  &.s {
    padding: var(--spacing-100) 0;

    > div {
      font: var(--mobile-text-regular);

      &.prize {
        /* stylelint-disable */
        font-size: 16px;
        font-weight: 800;
        font-style: italic;
        line-height: 16px;
        color: var(--text-primary);
        /* stylelint-enable */
      }

      &:first-child {
        min-width: 28px;
      }
    }

    &.active {
      /* stylelint-disable */
      background: radial-gradient(
        108.72% 47.22% at 50% 50%,
        rgba(254, 94, 1, 0.48) 0%,
        rgba(31, 31, 41, 0.48) 60.18%
      );
      /* stylelint-enable */
    }
  }
}

.current-user-pointer {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 18px;
  height: 16px;
  margin-right: var(--spacing-200);
  padding: var(--spacing-0) var(--spacing-075) var(--spacing-0)
    var(--spacing-075);

  font: var(--desktop-text-xs-medium);

  background: var(--background-brand, #fe5e01);
  border-radius: 3px 0 0 3px; /* stylelint-disable-line */

  .pointer {
    position: absolute;
    right: -8px;
    display: block;
    height: 16px;
  }
}

.currency-icon {
  min-width: 16px;
}
</style>
