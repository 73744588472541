<template>
  <NuxtI18nLink :to="`/tournaments/${tournamentId}`" class="link">
    <div class="wrapper">
      <div class="players">
        <StIcon name="live" size="12" class="live" />
        <span class="count">
          {{ formattedPlayersCount }}
        </span>
        <span class="players-name">
          {{ t('tournament.playersCount', playersCount) }}
        </span>
      </div>
      <div class="participate">
        {{ t('tournament.more') }}
        <StIcon name="chevron-right" size="16" />
      </div>
    </div>
  </NuxtI18nLink>
</template>

<script setup lang="ts">
const { t } = useI18n()
const { format } = useNumberFormatter()

const props = defineProps<{
  playersCount: number
  tournamentId: number
}>()

const formattedPlayersCount = computed(() => format(props.playersCount))
</script>

<style scoped>
.link {
  text-decoration: none;
}

.wrapper {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: var(--spacing-125) var(--spacing-150);

  background-color: var(--button-gray-default);
  border-radius: var(--border-radius-100);

  &:hover {
    background-color: var(--button-gray-hover);
  }
}

.players {
  display: flex;
  align-items: center;
}

.live {
  color: var(--text-link);
}

.count {
  margin-right: var(--spacing-050);
  margin-left: var(--spacing-025);
  font: var(--desktop-text-xs-semibold);
  color: var(--text-primary);
}

.players-name {
  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
}

.participate {
  display: flex;
  align-items: center;
  font: var(--desktop-text-sm-semibold);
  color: var(--text-primary);
}
</style>
